import { GlobalConfig } from './global-config';

let AureliaAuthConfig = {
    baseUrl:        GlobalConfig.backendBaseApiUrl,
    loginUrl:       'auth/login',
    loginRedirect:  '#/dashboard',
    logoutUrl:      'auth/logout',
    logoutRedirect: '#/',

    tokenName:   'token',
    tokenPrefix: 'aurelia',
};

export { AureliaAuthConfig };
