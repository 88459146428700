import { LocalStorage } from 'resources/services/local-storage';
import Backend          from 'i18next-xhr-backend';
import environment      from 'environment';

let setupConfig = (aurelia) => {
    let config = {
        backend:     {
            loadPath: environment.maintenance ? environment.translations.localEndpoint : environment.translations.remoteEndpoint,
        },
        lng:         environment.translations.default,
        fallbackLng: environment.translations.fallback,
        attributes:  ['t', 'i18n'],
        crossDomain: true,
        debug:       false,
    };

    let storage = aurelia.container.get(LocalStorage);
    let locale  = storage.get('locale');

    if (locale === null) {
        locale = config.lng;
        storage.set('locale', locale);
    }

    config.lng         = locale;
    config.attributes  = ['t', 'i18n'];
    config.nsSeparator = '::'; // namespace separator

    return config;
};

let AureliaI18NConfig = {
    configure: (aurelia, instance) => {
        instance.i18next.use(Backend);

        return instance.setup(setupConfig(aurelia));
    },
};

export { AureliaI18NConfig };
