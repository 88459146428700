import { inject }                     from 'aurelia-framework';
import { activationStrategy, Router } from 'aurelia-router';
import { PLATFORM }                   from 'aurelia-pal';
import { AuthorizeStep }              from 'aurelia-auth';
import { MenuAccessStep }             from 'resources/services/menu-access-step';
import { PostCompleteStep }           from 'resources/services/post-complete-step';
import environment                    from 'environment';

@inject(Router)
export class RouterConfig {

    /**
     * Constructor
     *
     * @param router
     */
    constructor(router) {
        this.router = router;
    }

    /**
     * Configure router
     */
    configure() {
        let appRouterConfig = (config) => {
            config.title = environment.productName;

            config.addPipelineStep('authorize', AuthorizeStep);
            config.addPreActivateStep(MenuAccessStep);
            config.addPipelineStep('postcomplete', PostCompleteStep);

            config.map([
                // ==================== dashboard ====================
                {
                    route:    ['', 'dashboard'],
                    name:     'dashboard',
                    moduleId: PLATFORM.moduleName('modules/core/dashboard/index'),
                    nav:      true,
                    title:    'Dashboard',
                },
                // ==================== account-settings ====================
                {
                    route:    'account-settings',
                    name:     'core.account-settings.index',
                    moduleId: PLATFORM.moduleName('modules/core/account-settings/index'),
                    nav:      false,
                    title:    'Definições da conta',
                    settings: {
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.account-settings'},
                        ],
                    },
                },
                // ==================== messages ====================
                {
                    route:    'personal-area/messages',
                    name:     'personal-area.messages.index',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/index'),
                    nav:      true,
                    title:    'Mensagens',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.messages'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'personal-area/messages/create',
                    name:     'personal-area.messages.create',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/create/index'),
                    nav:      false,
                    title:    'Criar mensagem',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.messages'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'personal-area/messages/:id/details',
                    name:     'personal-area.messages.details',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/details/index'),
                    nav:      false,
                    title:    'Detalhes da mensagem',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.messages'},
                            {title: 'page.breadcrumbs.details'},
                        ],
                    },
                },
                // ==================== notifications ====================
                {
                    route:    'personal-area/notifications',
                    name:     'personal-area.notifications.index',
                    moduleId: PLATFORM.moduleName('modules/personal-area/notifications/index'),
                    nav:      true,
                    title:    'Notificações',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.notifications'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'personal-area/notifications/:id/details',
                    name:     'personal-area.notifications.details',
                    moduleId: PLATFORM.moduleName('modules/personal-area/notifications/details/index'),
                    nav:      false,
                    title:    'Detalhes da notificação',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.personal-area'},
                            {title: 'page.breadcrumbs.notifications'},
                            {title: 'page.breadcrumbs.details'},
                        ],
                    },
                },
                // ==================== users ====================
                {
                    route:    'administration/users',
                    name:     'administration.users.index',
                    moduleId: PLATFORM.moduleName('modules/administration/users/index'),
                    nav:      true,
                    title:    'Utilizadores',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.users'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'administration/users/create',
                    name:     'administration.users.create',
                    moduleId: PLATFORM.moduleName('modules/administration/users/create/index'),
                    nav:      false,
                    title:    'Criar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.users'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'administration/users/:id/edit',
                    name:     'administration.users.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/users/edit/index'),
                    nav:      false,
                    title:    'Editar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.users'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'administration/users/:id/view',
                    name:     'administration.users.view',
                    moduleId: PLATFORM.moduleName('modules/administration/users/view/index'),
                    nav:      false,
                    title:    'Visualizar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.users'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== roles ====================
                {
                    route:    'administration/roles',
                    name:     'administration.roles.index',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/index'),
                    nav:      true,
                    title:    'Perfis',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.roles'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'administration/roles/create',
                    name:     'administration.roles.create',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/create/index'),
                    nav:      false,
                    title:    'Criar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.roles'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'administration/roles/:id/edit',
                    name:     'administration.roles.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/edit/index'),
                    nav:      false,
                    title:    'Editar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.roles'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'administration/roles/:id/view',
                    name:     'administration.roles.view',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/view/index'),
                    nav:      false,
                    title:    'Visualizar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.roles'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== countries ====================
                {
                    route:    'administration/countries',
                    name:     'administration.countries.index',
                    moduleId: PLATFORM.moduleName('modules/administration/countries/index'),
                    nav:      true,
                    title:    'Países',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.countries'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== translations ====================
                {
                    route:    'administration/translations',
                    name:     'administration.translations.index',
                    moduleId: PLATFORM.moduleName('modules/administration/translations/index'),
                    nav:      true,
                    title:    'Traduções',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.translations'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'administration/translations/create',
                    name:     'administration.translations.create',
                    moduleId: PLATFORM.moduleName('modules/administration/translations/create/index'),
                    nav:      false,
                    title:    'Criar tradução',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.translations'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'administration/translations/:id/edit',
                    name:     'administration.translations.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/translations/edit/index'),
                    nav:      false,
                    title:    'Editar tradução',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.translations'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== mail-settings ====================
                {
                    route:    'administration/mail-settings/edit',
                    name:     'administration.mail-settings.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/mail-settings/edit/index'),
                    nav:      true,
                    title:    'Configurações de email',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.mail-settings'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== locations ====================
                {
                    route:    'administration/locations/index',
                    name:     'administration.locations.index',
                    moduleId: PLATFORM.moduleName('modules/administration/locations/index'),
                    nav:      true,
                    title:    'Localizações',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.administration'},
                            {title: 'page.breadcrumbs.locations'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== regulations ====================
                {
                    route:    'management/regulations',
                    name:     'management.regulations.index',
                    moduleId: PLATFORM.moduleName('modules/management/regulations/index'),
                    nav:      true,
                    title:    'Regulamentações',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.applicable-regulation'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'management/regulations/create',
                    name:     'management.regulations.create',
                    moduleId: PLATFORM.moduleName('modules/management/regulations/create/index'),
                    nav:      false,
                    title:    'Criar regulamentação',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.applicable-regulation'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'management/regulations/:id/edit',
                    name:     'management.regulations.edit',
                    moduleId: PLATFORM.moduleName('modules/management/regulations/edit/index'),
                    nav:      false,
                    title:    'Editar regulamentação',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.applicable-regulation'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'management/regulations/:id/view',
                    name:     'management.regulations.view',
                    moduleId: PLATFORM.moduleName('modules/management/regulations/view/index'),
                    nav:      false,
                    title:    'Visualizar regulamentação',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.applicable-regulation'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== building-types ====================
                {
                    route:    'management/building-types',
                    name:     'management.building-types.index',
                    moduleId: PLATFORM.moduleName('modules/management/building-types/index'),
                    nav:      true,
                    title:    'Tipos de edifício',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.building-types'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'management/building-types/create',
                    name:     'management.building-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/building-types/create/index'),
                    nav:      false,
                    title:    'Criar tipo de edifício',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.building-types'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'management/building-types/:id/edit',
                    name:     'management.building-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/building-types/edit/index'),
                    nav:      false,
                    title:    'Editar tipo de edifício',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.building-types'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'management/building-types/:id/view',
                    name:     'management.building-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/building-types/view/index'),
                    nav:      false,
                    title:    'Visualizar tipo de edifício',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.building-types'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== lift-types ====================
                {
                    route:    'management/lift-types',
                    name:     'management.lift-types.index',
                    moduleId: PLATFORM.moduleName('modules/management/lift-types/index'),
                    nav:      true,
                    title:    'Tipos de ascensor',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.lift-types'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'management/lift-types/create',
                    name:     'management.lift-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/lift-types/create/index'),
                    nav:      false,
                    title:    'Criar tipo de ascensor',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.lift-types'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'management/lift-types/:id/edit',
                    name:     'management.lift-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/lift-types/edit/index'),
                    nav:      false,
                    title:    'Editar tipo de ascensor',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.lift-types'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'management/lift-types/:id/view',
                    name:     'management.lift-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/lift-types/view/index'),
                    nav:      false,
                    title:    'Visualizar tipo de ascensor',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.lift-types'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== clauses ====================
                {
                    route:    'management/clauses',
                    name:     'management.clauses.index',
                    moduleId: PLATFORM.moduleName('modules/management/clauses/index'),
                    nav:      true,
                    title:    'Cláusulas',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.clauses'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'management/clauses/create',
                    name:     'management.clauses.create',
                    moduleId: PLATFORM.moduleName('modules/management/clauses/create/index'),
                    nav:      false,
                    title:    'Criar cláusula',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.clauses'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'management/clauses/:id/edit',
                    name:     'management.clauses.edit',
                    moduleId: PLATFORM.moduleName('modules/management/clauses/edit/index'),
                    nav:      false,
                    title:    'Editar cláusula',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.clauses'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'management/clauses/:id/view',
                    name:     'management.clauses.view',
                    moduleId: PLATFORM.moduleName('modules/management/clauses/view/index'),
                    nav:      false,
                    title:    'Visualizar cláusula',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.clauses'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== settings ====================
                {
                    route:    'management/settings/edit',
                    name:     'management.settings.edit',
                    moduleId: PLATFORM.moduleName('modules/management/settings/edit/index'),
                    nav:      true,
                    title:    'Configurações',
                    settings: {
                        menu:        'management',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.management'},
                            {title: 'page.breadcrumbs.settings'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== managing-entities ====================
                {
                    route:    'entities/managing-entities',
                    name:     'entities.managing-entities.index',
                    moduleId: PLATFORM.moduleName('modules/entities/managing-entities/index'),
                    nav:      true,
                    title:    'Entidades gestoras',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.managing-entities'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/managing-entities/create',
                    name:     'entities.managing-entities.create',
                    moduleId: PLATFORM.moduleName('modules/entities/managing-entities/create/index'),
                    nav:      false,
                    title:    'Criar entidade gestora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.managing-entities'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/managing-entities/:id/edit',
                    name:     'entities.managing-entities.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/managing-entities/edit/index'),
                    nav:      false,
                    title:    'Editar entidade gestora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.managing-entities'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/managing-entities/:id/view',
                    name:     'entities.managing-entities.view',
                    moduleId: PLATFORM.moduleName('modules/entities/managing-entities/view/index'),
                    nav:      false,
                    title:    'Visualizar entidade gestora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.managing-entities'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== controlling-entities ====================
                {
                    route:    'entities/controlling-entities',
                    name:     'entities.controlling-entities.index',
                    moduleId: PLATFORM.moduleName('modules/entities/controlling-entities/index'),
                    nav:      true,
                    title:    'Entidades fiscalizadoras',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.controlling-entities'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/controlling-entities/create',
                    name:     'entities.controlling-entities.create',
                    moduleId: PLATFORM.moduleName('modules/entities/controlling-entities/create/index'),
                    nav:      false,
                    title:    'Criar entidade fiscalizadora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.controlling-entities'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/controlling-entities/:id/edit',
                    name:     'entities.controlling-entities.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/controlling-entities/edit/index'),
                    nav:      false,
                    title:    'Editar entidade fiscalizadora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.controlling-entities'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/controlling-entities/:id/view',
                    name:     'entities.controlling-entities.view',
                    moduleId: PLATFORM.moduleName('modules/entities/controlling-entities/view/index'),
                    nav:      false,
                    title:    'Visualizar entidade fiscalizadora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.controlling-entities'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== inspecting-entities ====================
                {
                    route:    'entities/inspecting-entities',
                    name:     'entities.inspecting-entities.index',
                    moduleId: PLATFORM.moduleName('modules/entities/inspecting-entities/index'),
                    nav:      true,
                    title:    'Entidades inspetoras',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.inspecting-entities'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/inspecting-entities/create',
                    name:     'entities.inspecting-entities.create',
                    moduleId: PLATFORM.moduleName('modules/entities/inspecting-entities/create/index'),
                    nav:      false,
                    title:    'Criar entidade inspetora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.inspecting-entities'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/inspecting-entities/:id/edit',
                    name:     'entities.inspecting-entities.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/inspecting-entities/edit/index'),
                    nav:      false,
                    title:    'Editar entidade inspetora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.inspecting-entities'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/inspecting-entities/:id/view',
                    name:     'entities.inspecting-entities.view',
                    moduleId: PLATFORM.moduleName('modules/entities/inspecting-entities/view/index'),
                    nav:      false,
                    title:    'Visualizar entidade inspetora',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.inspecting-entities'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== maintenance-companies ====================
                {
                    route:    'entities/maintenance-companies',
                    name:     'entities.maintenance-companies.index',
                    moduleId: PLATFORM.moduleName('modules/entities/maintenance-companies/index'),
                    nav:      true,
                    title:    'Empresas de manutenção',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.maintenance-companies'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/maintenance-companies/create',
                    name:     'entities.maintenance-companies.create',
                    moduleId: PLATFORM.moduleName('modules/entities/maintenance-companies/create/index'),
                    nav:      false,
                    title:    'Criar empresa de manutenção',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.maintenance-companies'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/maintenance-companies/:id/edit',
                    name:     'entities.maintenance-companies.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/maintenance-companies/edit/index'),
                    nav:      false,
                    title:    'Editar empresa de manutenção',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.maintenance-companies'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/maintenance-companies/:id/view',
                    name:     'entities.maintenance-companies.view',
                    moduleId: PLATFORM.moduleName('modules/entities/maintenance-companies/view/index'),
                    nav:      false,
                    title:    'Visualizar empresa de manutenção',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.maintenance-companies'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== installing-companies ====================
                {
                    route:    'entities/installing-companies',
                    name:     'entities.installing-companies.index',
                    moduleId: PLATFORM.moduleName('modules/entities/installing-companies/index'),
                    nav:      true,
                    title:    'Empresas de instalação',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.installing-companies'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/installing-companies/create',
                    name:     'entities.installing-companies.create',
                    moduleId: PLATFORM.moduleName('modules/entities/installing-companies/create/index'),
                    nav:      false,
                    title:    'Criar empresa de instalação',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.installing-companies'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/installing-companies/:id/edit',
                    name:     'entities.installing-companies.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/installing-companies/edit/index'),
                    nav:      false,
                    title:    'Editar empresa de instalação',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.installing-companies'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/installing-companies/:id/view',
                    name:     'entities.installing-companies.view',
                    moduleId: PLATFORM.moduleName('modules/entities/installing-companies/view/index'),
                    nav:      false,
                    title:    'Visualizar empresa de instalação',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.installing-companies'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== owners ====================
                {
                    route:    'entities/owners',
                    name:     'entities.owners.index',
                    moduleId: PLATFORM.moduleName('modules/entities/owners/index'),
                    nav:      true,
                    title:    'Proprietários/Administradores',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.owners'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/owners/create',
                    name:     'entities.owners.create',
                    moduleId: PLATFORM.moduleName('modules/entities/owners/create/index'),
                    nav:      false,
                    title:    'Criar proprietário/administrador',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.owners'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/owners/:id/edit',
                    name:     'entities.owners.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/owners/edit/index'),
                    nav:      false,
                    title:    'Editar proprietário/administrador',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.owners'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/owners/:id/view',
                    name:     'entities.owners.view',
                    moduleId: PLATFORM.moduleName('modules/entities/owners/view/index'),
                    nav:      false,
                    title:    'Visualizar proprietário/administrador',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.owners'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== controlling-agents ====================
                {
                    route:    'entities/controlling-agents',
                    name:     'entities.controlling-agents.index',
                    moduleId: PLATFORM.moduleName('modules/entities/controlling-agents/index'),
                    nav:      true,
                    title:    'Agentes de fiscalização',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.controlling-agents'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/controlling-agents/create',
                    name:     'entities.controlling-agents.create',
                    moduleId: PLATFORM.moduleName('modules/entities/controlling-agents/create/index'),
                    nav:      false,
                    title:    'Criar agente de fiscalização',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.controlling-agents'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/controlling-agents/:id/edit',
                    name:     'entities.controlling-agents.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/controlling-agents/edit/index'),
                    nav:      false,
                    title:    'Editar agente de fiscalização',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.controlling-agents'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/controlling-agents/:id/view',
                    name:     'entities.controlling-agents.view',
                    moduleId: PLATFORM.moduleName('modules/entities/controlling-agents/view/index'),
                    nav:      false,
                    title:    'Visualizar agente de fiscalização',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.controlling-agents'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== inspectors ====================
                {
                    route:    'entities/inspectors',
                    name:     'entities.inspectors.index',
                    moduleId: PLATFORM.moduleName('modules/entities/inspectors/index'),
                    nav:      true,
                    title:    'Inspetores',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.inspectors'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/inspectors/create',
                    name:     'entities.inspectors.create',
                    moduleId: PLATFORM.moduleName('modules/entities/inspectors/create/index'),
                    nav:      false,
                    title:    'Criar inspetor',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.inspectors'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/inspectors/:id/edit',
                    name:     'entities.inspectors.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/inspectors/edit/index'),
                    nav:      false,
                    title:    'Editar inspetor',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.inspectors'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/inspectors/:id/view',
                    name:     'entities.inspectors.view',
                    moduleId: PLATFORM.moduleName('modules/entities/inspectors/view/index'),
                    nav:      false,
                    title:    'Visualizar inspetor',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.inspectors'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== maintenance-technicians ====================
                {
                    route:    'entities/maintenance-technicians',
                    name:     'entities.maintenance-technicians.index',
                    moduleId: PLATFORM.moduleName('modules/entities/maintenance-technicians/index'),
                    nav:      true,
                    title:    'Técnicos de manutenção',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.maintenance-technicians'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'entities/maintenance-technicians/create',
                    name:     'entities.maintenance-technicians.create',
                    moduleId: PLATFORM.moduleName('modules/entities/maintenance-technicians/create/index'),
                    nav:      false,
                    title:    'Criar técnico de manutenção',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.maintenance-technicians'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'entities/maintenance-technicians/:id/edit',
                    name:     'entities.maintenance-technicians.edit',
                    moduleId: PLATFORM.moduleName('modules/entities/maintenance-technicians/edit/index'),
                    nav:      false,
                    title:    'Editar técnico de manutenção',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.maintenance-technicians'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'entities/maintenance-technicians/:id/view',
                    name:     'entities.maintenance-technicians.view',
                    moduleId: PLATFORM.moduleName('modules/entities/maintenance-technicians/view/index'),
                    nav:      false,
                    title:    'Visualizar técnico de manutenção',
                    settings: {
                        menu:        'entities',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.entities'},
                            {title: 'page.breadcrumbs.maintenance-technicians'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== lifts ====================
                {
                    route:    'lifts/lifts',
                    name:     'lifts.lifts.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/index'),
                    nav:      true,
                    title:    'Ascensores',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'lifts/lifts/create',
                    name:     'lifts.lifts.create',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/create/index'),
                    nav:      false,
                    title:    'Criar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'lifts/lifts/:id/edit',
                    name:     'lifts.lifts.edit',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/edit/index'),
                    nav:      false,
                    title:    'Editar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'lifts/lifts/:id/view',
                    name:     'lifts.lifts.view',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/view/index'),
                    nav:      false,
                    title:    'Visualizar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== lifts-maintenance-logs ====================
                {
                    route:    'lifts/maintenance-logs',
                    name:     'lifts.maintenance-logs.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/maintenance-logs/index'),
                    nav:      true,
                    title:    'Registo de manutenção',
                    settings: {
                        menu: 'lifts',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.lifts' },
                            { title: 'page.breadcrumbs.maintenance-log' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                // ==================== awaiting-validation-lifts ====================
                {
                    route:    'lifts/awaiting-validation',
                    name:     'lifts.awaiting-validation.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/awaiting-validation/index'),
                    nav:      true,
                    title:    'Ascensores a aguardar validação',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.awaiting-validation'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'lifts/awaiting-validation/:id/validate',
                    name:     'lifts.awaiting-validation.validate',
                    moduleId: PLATFORM.moduleName('modules/lifts/awaiting-validation/validate/index'),
                    nav:      false,
                    title:    'Validar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.awaiting-validation'},
                            {title: 'page.breadcrumbs.validate-record'},
                        ],
                    },
                },
                {
                    route:    'lifts/awaiting-validation/:id/view',
                    name:     'lifts.awaiting-validation.view',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/view/index'),
                    nav:      false,
                    title:    'Visualizar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== approved-lifts ====================
                {
                    route:    'lifts/approved',
                    name:     'lifts.approved.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/approved/index'),
                    nav:      true,
                    title:    'Ascensores aprovados',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.approved'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'lifts/approved/:id/view',
                    name:     'lifts.approved.view',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/view/index'),
                    nav:      false,
                    title:    'Visualizar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== with-expiring-certificate-lifts ====================
                {
                    route:    'lifts/with-expiring-certificate',
                    name:     'lifts.with-expiring-certificate.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/with-expiring-certificate/index'),
                    nav:      true,
                    title:    'Ascensores com certificado a expirar',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.with-expiring-certificate'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'lifts/with-expiring-certificate/:id/view',
                    name:     'lifts.with-expiring-certificate.view',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/view/index'),
                    nav:      false,
                    title:    'Visualizar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== without-maintenance-company-lifts ====================
                {
                    route:    'lifts/without-maintenance-company',
                    name:     'lifts.without-maintenance-company.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/without-maintenance-company/index'),
                    nav:      true,
                    title:    'Ascensores sem EMIE',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.without-emie'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'lifts/without-maintenance-company/:id/edit',
                    name:     'lifts.without-maintenance-company.edit',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/edit/index'),
                    nav:      false,
                    title:    'Editar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.without-emie'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'lifts/without-maintenance-company/:id/view',
                    name:     'lifts.without-maintenance-company.view',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/view/index'),
                    nav:      false,
                    title:    'Visualizar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== rejected-lifts ====================
                {
                    route:    'lifts/rejected',
                    name:     'lifts.rejected.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/rejected/index'),
                    nav:      true,
                    title:    'Ascensores reprovados',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.rejected'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'lifts/rejected/:id/view',
                    name:     'lifts.rejected.view',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/view/index'),
                    nav:      false,
                    title:    'Visualizar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== sealed-lifts ====================
                {
                    route:    'lifts/sealed',
                    name:     'lifts.sealed.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/sealed/index'),
                    nav:      true,
                    title:    'Ascensores selados',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.sealed'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'lifts/sealed/:id/view',
                    name:     'lifts.sealed.view',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/view/index'),
                    nav:      false,
                    title:    'Visualizar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== disassembled-lifts ====================
                {
                    route:    'lifts/disassembled',
                    name:     'lifts.disassembled.index',
                    moduleId: PLATFORM.moduleName('modules/lifts/disassembled/index'),
                    nav:      true,
                    title:    'Ascensores desmontados',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.disassembled'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'lifts/disassembled/:id/view',
                    name:     'lifts.disassembled.view',
                    moduleId: PLATFORM.moduleName('modules/lifts/lifts/view/index'),
                    nav:      false,
                    title:    'Visualizar ascensor',
                    settings: {
                        menu:        'lifts',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.lifts'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== lifts-search ====================
                {
                    route:              'lifts/search/:address',
                    name:               'lifts.search',
                    moduleId:           PLATFORM.moduleName('modules/lifts/search/index'),
                    activationStrategy: activationStrategy.replace,
                    nav:                false,
                    title:              'Pesquisa de ascensores',
                    settings:           {
                        menu:        'lifts',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.lifts' },
                            { title: 'page.breadcrumbs.search' },
                        ],
                    },
                },
                // ==================== periodic-inspections/planned ====================
                {
                    route:    'periodic-inspections/planned',
                    name:     'periodic-inspections.planned.index',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/planned/index'),
                    nav:      true,
                    title:    'Inspeções periódicas previstas',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.planned'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== periodic-inspections/awaiting-payment ====================
                {
                    route:    'periodic-inspections/awaiting-payment',
                    name:     'periodic-inspections.awaiting-payment.index',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-payment/index'),
                    nav:      true,
                    title:    'Inspeções periódicas a aguardar pagamento',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.request/payment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'periodic-inspections/awaiting-payment/payments/:id/pay',
                    name:     'periodic-inspections.awaiting-payment.payments.pay',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-payment/payments/pay/index'),
                    nav:      false,
                    title:    'Confirmar pagamento',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.request/payment'},
                            {title: 'page.breadcrumbs.payments'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'periodic-inspections/awaiting-payment/payments/:id/view',
                    name:     'periodic-inspections.awaiting-payment.payments.view',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-payment/payments/view/index'),
                    nav:      false,
                    title:    'Visualizar dados do pagamento',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.request/payment'},
                            {title: 'page.breadcrumbs.payments'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== periodic-inspections/awaiting-assignment ====================
                {
                    route:    'periodic-inspections/awaiting-assignment',
                    name:     'periodic-inspections.awaiting-assignment.index',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-assignment/index'),
                    nav:      true,
                    title:    'Inspeções periódicas a aguardar atribuição de EIIE',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.ie-assignment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== periodic-inspections/scheduling ====================
                {
                    route:    'periodic-inspections/scheduling',
                    name:     'periodic-inspections.scheduling.index',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/scheduling/index'),
                    nav:      true,
                    title:    'Inspeções periódicas a aguardar agendamento',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'periodic-inspections/scheduling/:id/edit',
                    name:     'periodic-inspections.scheduling.edit',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/scheduling/edit/index'),
                    nav:      false,
                    title:    'Agendar inspeção periódica',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'periodic-inspections/scheduling/:id/confirm',
                    name:     'periodic-inspections.scheduling.confirm',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/scheduling/confirm/index'),
                    nav:      false,
                    title:    'Confirmar agendamento de inspeção periódica',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== periodic-inspections/awaiting-execution ====================
                {
                    route:    'periodic-inspections/awaiting-execution',
                    name:     'periodic-inspections.awaiting-execution.index',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-execution/index'),
                    nav:      true,
                    title:    'Inspeções periódicas a aguardar realização',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'periodic-inspections/awaiting-execution/:id/edit',
                    name:     'periodic-inspections.awaiting-execution.edit',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-execution/edit/index'),
                    nav:      false,
                    title:    'Realizar inspeção periódica',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'periodic-inspections/awaiting-execution/:id/reassign',
                    name:     'periodic-inspections.awaiting-execution.reassign',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-execution/reassign/index'),
                    nav:      false,
                    title:    'Alterar técnico de manutenção',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'periodic-inspections/awaiting-execution/:id/reassign-inspector',
                    name:     'periodic-inspections.awaiting-execution.reassign-inspector',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-execution/reassign-inspector/index'),
                    nav:      false,
                    title:    'Alterar inspector de inspeção periódica',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'periodic-inspections/awaiting-execution/:id/reschedule',
                    name:     'periodic-inspections.awaiting-execution.reschedule',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/awaiting-execution/reschedule/index'),
                    nav:      false,
                    title:    'Reagendar inspeção periódica',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== periodic-inspections/in-execution ====================
                {
                    route:    'periodic-inspections/in-execution',
                    name:     'periodic-inspections.in-execution.index',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/in-execution/index'),
                    nav:      true,
                    title:    'Inspeções periódicas em realização',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.in-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== periodic-inspections/results ====================
                {
                    route:    'periodic-inspections/results',
                    name:     'periodic-inspections.results.index',
                    moduleId: PLATFORM.moduleName('modules/periodic-inspections/results/index'),
                    nav:      true,
                    title:    'Inspeções periódicas realizadas',
                    settings: {
                        menu:        'periodic-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.periodic-inspections'},
                            {title: 'page.breadcrumbs.results'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== reinspections/awaiting-payment ====================
                {
                    route:    'reinspections/awaiting-payment',
                    name:     'reinspections.awaiting-payment.index',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-payment/index'),
                    nav:      true,
                    title:    'Reinspeções a aguardar pagamento',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.request/payment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'reinspections/awaiting-payment/payments/:id/pay',
                    name:     'reinspections.awaiting-payment.payments.pay',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-payment/payments/pay/index'),
                    nav:      false,
                    title:    'Confirmar pagamento',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.request/payment'},
                            {title: 'page.breadcrumbs.payments'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'reinspections/awaiting-payment/payments/:id/view',
                    name:     'reinspections.awaiting-payment.payments.view',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-payment/payments/view/index'),
                    nav:      false,
                    title:    'Visualizar dados do pagamento',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.request/payment'},
                            {title: 'page.breadcrumbs.payments'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== reinspections/awaiting-assignment ====================
                {
                    route:    'reinspections/awaiting-assignment',
                    name:     'reinspections.awaiting-assignment.index',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-assignment/index'),
                    nav:      true,
                    title:    'Reinspeções a aguardar atribuição de EIIE',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.ie-assignment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== reinspections/scheduling ====================
                {
                    route:    'reinspections/scheduling',
                    name:     'reinspections.scheduling.index',
                    moduleId: PLATFORM.moduleName('modules/reinspections/scheduling/index'),
                    nav:      true,
                    title:    'Reinspeções a aguardar agendamento',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'reinspections/scheduling/:id/edit',
                    name:     'reinspections.scheduling.edit',
                    moduleId: PLATFORM.moduleName('modules/reinspections/scheduling/edit/index'),
                    nav:      false,
                    title:    'Agendar reinspeção',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'reinspections/scheduling/:id/confirm',
                    name:     'reinspections.scheduling.confirm',
                    moduleId: PLATFORM.moduleName('modules/reinspections/scheduling/confirm/index'),
                    nav:      false,
                    title:    'Confirmar agendamento de reinspeção',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== reinspections/awaiting-execution ====================
                {
                    route:    'reinspections/awaiting-execution',
                    name:     'reinspections.awaiting-execution.index',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-execution/index'),
                    nav:      true,
                    title:    'Reinspeções a aguardar realização',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'reinspections/awaiting-execution/:id/edit',
                    name:     'reinspections.awaiting-execution.edit',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-execution/edit/index'),
                    nav:      false,
                    title:    'Realizar reinspeção',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'reinspections/awaiting-execution/:id/reassign',
                    name:     'reinspections.awaiting-execution.reassign',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-execution/reassign/index'),
                    nav:      false,
                    title:    'Alterar técnico de manutenção',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'reinspections/awaiting-execution/:id/reassign-inspector',
                    name:     'reinspections.awaiting-execution.reassign-inspector',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-execution/reassign-inspector/index'),
                    nav:      false,
                    title:    'Alterar inspector de reinspeção',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'reinspections/awaiting-execution/:id/reschedule',
                    name:     'reinspections.awaiting-execution.reschedule',
                    moduleId: PLATFORM.moduleName('modules/reinspections/awaiting-execution/reschedule/index'),
                    nav:      false,
                    title:    'Reagendar reinspeção',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== reinspections/in-execution ====================
                {
                    route:    'reinspections/in-execution',
                    name:     'reinspections.in-execution.index',
                    moduleId: PLATFORM.moduleName('modules/reinspections/in-execution/index'),
                    nav:      true,
                    title:    'Reinspeções em realização',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.in-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== reinspections/results ====================
                {
                    route:    'reinspections/results',
                    name:     'reinspections.results.index',
                    moduleId: PLATFORM.moduleName('modules/reinspections/results/index'),
                    nav:      true,
                    title:    'Reinspeções realizadas',
                    settings: {
                        menu:        'reinspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.reinspections'},
                            {title: 'page.breadcrumbs.results'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== extraordinary-inspections/requests ====================
                {
                    route:    'extraordinary-inspections/requests',
                    name:     'extraordinary-inspections.requests.index',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/requests/index'),
                    nav:      true,
                    title:    'Pedido de inspeção extraordinária',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.request'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                // ==================== extraordinary-inspections/awaiting-payment ====================
                {
                    route:    'extraordinary-inspections/awaiting-payment',
                    name:     'extraordinary-inspections.awaiting-payment.index',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-payment/index'),
                    nav:      true,
                    title:    'Inspeções extraordinárias a aguardar pagamento',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.payment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'extraordinary-inspections/awaiting-payment/payments/:id/pay',
                    name:     'extraordinary-inspections.awaiting-payment.payments.pay',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-payment/payments/pay/index'),
                    nav:      false,
                    title:    'Confirmar pagamento',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.payment'},
                            {title: 'page.breadcrumbs.payments'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'extraordinary-inspections/awaiting-payment/payments/:id/view',
                    name:     'extraordinary-inspections.awaiting-payment.payments.view',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-payment/payments/view/index'),
                    nav:      false,
                    title:    'Visualizar dados do pagamento',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.payment'},
                            {title: 'page.breadcrumbs.payments'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== extraordinary-inspections/awaiting-assignment ====================
                {
                    route:    'extraordinary-inspections/awaiting-assignment',
                    name:     'extraordinary-inspections.awaiting-assignment.index',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-assignment/index'),
                    nav:      true,
                    title:    'Inspeções extraordinárias a aguardar atribuição de EIIE',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.ie-assignment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== extraordinary-inspections/scheduling ====================
                {
                    route:    'extraordinary-inspections/scheduling',
                    name:     'extraordinary-inspections.scheduling.index',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/scheduling/index'),
                    nav:      true,
                    title:    'Inspeções extraordinárias a aguardar agendamento',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'extraordinary-inspections/scheduling/:id/edit',
                    name:     'extraordinary-inspections.scheduling.edit',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/scheduling/edit/index'),
                    nav:      false,
                    title:    'Agendar inspeção extraordinária',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'extraordinary-inspections/scheduling/:id/confirm',
                    name:     'extraordinary-inspections.scheduling.confirm',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/scheduling/confirm/index'),
                    nav:      false,
                    title:    'Confirmar agendamento de inspeção extraordinária',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== extraordinary-inspections/awaiting-execution ====================
                {
                    route:    'extraordinary-inspections/awaiting-execution',
                    name:     'extraordinary-inspections.awaiting-execution.index',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-execution/index'),
                    nav:      true,
                    title:    'Inspeções extraordinárias a aguardar realização',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'extraordinary-inspections/awaiting-execution/:id/edit',
                    name:     'extraordinary-inspections.awaiting-execution.edit',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-execution/edit/index'),
                    nav:      false,
                    title:    'Realizar inspeção extrardinária',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'extraordinary-inspections/awaiting-execution/:id/reassign',
                    name:     'extraordinary-inspections.awaiting-execution.reassign',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-execution/reassign/index'),
                    nav:      false,
                    title:    'Alterar técnico de manutenção',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'extraordinary-inspections/awaiting-execution/:id/reassign-inspector',
                    name:     'extraordinary-inspections.awaiting-execution.reassign-inspector',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-execution/reassign-inspector/index'),
                    nav:      false,
                    title:    'Alterar inspector de inspeção extraordinária',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'extraordinary-inspections/awaiting-execution/:id/reschedule',
                    name:     'extraordinary-inspections.awaiting-execution.reschedule',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/awaiting-execution/reschedule/index'),
                    nav:      false,
                    title:    'Reagendar inspeção extraordinária',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== extraordinary-inspections/in-execution ====================
                {
                    route:    'extraordinary-inspections/in-execution',
                    name:     'extraordinary-inspections.in-execution.index',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/in-execution/index'),
                    nav:      true,
                    title:    'Inspeções extraordinárias em realização',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.in-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== extraordinary-inspections/results ====================
                {
                    route:    'extraordinary-inspections/results',
                    name:     'extraordinary-inspections.results.index',
                    moduleId: PLATFORM.moduleName('modules/extraordinary-inspections/results/index'),
                    nav:      true,
                    title:    'Inspeções extraordinárias realizadas',
                    settings: {
                        menu:        'extraordinary-inspections',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.extraordinary-inspections'},
                            {title: 'page.breadcrumbs.results'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== sealings/requests ====================
                {
                    route:    'sealings/requests',
                    name:     'sealings.requests.index',
                    moduleId: PLATFORM.moduleName('modules/sealings/requests/index'),
                    nav:      true,
                    title:    'Pedidos de selagem',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.request'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'sealings/requests/create',
                    name:     'sealings.requests.create',
                    moduleId: PLATFORM.moduleName('modules/sealings/requests/create/index'),
                    nav:      false,
                    title:    'Criar pedido de selagem',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.request'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                {
                    route:    'sealings/requests/:id/edit',
                    name:     'sealings.requests.edit',
                    moduleId: PLATFORM.moduleName('modules/sealings/requests/edit/index'),
                    nav:      false,
                    title:    'Resposta ao pedido de selagem',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.requests'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== sealings/awaiting-payment ====================
                {
                    route:    'sealings/awaiting-payment',
                    name:     'sealings.awaiting-payment.index',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-payment/index'),
                    nav:      true,
                    title:    'Selagens a aguardar pagamento',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.payment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'sealings/awaiting-payment/payments/:id/pay',
                    name:     'sealings.awaiting-payment.payments.pay',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-payment/payments/pay/index'),
                    nav:      false,
                    title:    'Confirmar pagamento',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.payment'},
                            {title: 'page.breadcrumbs.payments'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealings/awaiting-payment/payments/:id/view',
                    name:     'sealings.awaiting-payment.payments.view',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-payment/payments/view/index'),
                    nav:      false,
                    title:    'Visualizar dados do pagamento',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.payment'},
                            {title: 'page.breadcrumbs.payments'},
                            {title: 'page.breadcrumbs.view-record'},
                        ],
                    },
                },
                // ==================== sealings/awaiting-assignment ====================
                {
                    route:    'sealings/awaiting-assignment',
                    name:     'sealings.awaiting-assignment.index',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-assignment/index'),
                    nav:      true,
                    title:    'Selagens a aguardar atribuição de EIIE',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.ie-assignment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== sealings/scheduling ====================
                {
                    route:    'sealings/scheduling',
                    name:     'sealings.scheduling.index',
                    moduleId: PLATFORM.moduleName('modules/sealings/scheduling/index'),
                    nav:      true,
                    title:    'Selagens a aguardar agendamento',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'sealings/scheduling/:id/edit',
                    name:     'sealings.scheduling.edit',
                    moduleId: PLATFORM.moduleName('modules/sealings/scheduling/edit/index'),
                    nav:      false,
                    title:    'Agendar selagem',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealings/scheduling/:id/confirm',
                    name:     'sealings.scheduling.confirm',
                    moduleId: PLATFORM.moduleName('modules/sealings/scheduling/confirm/index'),
                    nav:      false,
                    title:    'Confirmar agendamento de selagem',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== sealings/awaiting-execution ====================
                {
                    route:    'sealings/awaiting-execution',
                    name:     'sealings.awaiting-execution.index',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-execution/index'),
                    nav:      true,
                    title:    'Selagens a aguardar realização',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'sealings/awaiting-execution/:id/edit',
                    name:     'sealings.awaiting-execution.edit',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-execution/edit/index'),
                    nav:      false,
                    title:    'Realizar selagem',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealings/awaiting-execution/:id/reassign',
                    name:     'sealings.awaiting-execution.reassign',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-execution/reassign/index'),
                    nav:      false,
                    title:    'Alterar técnico de manutenção',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealings/awaiting-execution/:id/reassign-inspector',
                    name:     'sealings.awaiting-execution.reassign-inspector',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-execution/reassign-inspector/index'),
                    nav:      false,
                    title:    'Alterar inspector de selagem',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealings/awaiting-execution/:id/reschedule',
                    name:     'sealings.awaiting-execution.reschedule',
                    moduleId: PLATFORM.moduleName('modules/sealings/awaiting-execution/reschedule/index'),
                    nav:      false,
                    title:    'Reagendar selagem',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== sealings/in-execution ====================
                {
                    route:    'sealings/in-execution',
                    name:     'sealings.in-execution.index',
                    moduleId: PLATFORM.moduleName('modules/sealings/in-execution/index'),
                    nav:      true,
                    title:    'Selagens em realização',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.in-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== sealings/results ====================
                {
                    route:    'sealings/results',
                    name:     'sealings.results.index',
                    moduleId: PLATFORM.moduleName('modules/sealings/results/index'),
                    nav:      true,
                    title:    'Selagens realizadas',
                    settings: {
                        menu:        'sealings',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealings'},
                            {title: 'page.breadcrumbs.results'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== sealing-orders/requests ====================
                {
                    route:    'sealing-orders/requests',
                    name:     'sealing-orders.requests.index',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/requests/index'),
                    nav:      true,
                    title:    'Ordem de selagem',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.request'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                // ==================== sealing-orders/awaiting-assignment ====================
                {
                    route:    'sealing-orders/awaiting-assignment',
                    name:     'sealing-orders.awaiting-assignment.index',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/awaiting-assignment/index'),
                    nav:      true,
                    title:    'Ordens de selagem a aguardar atribuição de EIIE',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.ie-assignment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== sealing-orders/scheduling ====================
                {
                    route:    'sealing-orders/scheduling',
                    name:     'sealing-orders.scheduling.index',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/scheduling/index'),
                    nav:      true,
                    title:    'Ordens de selagem a aguardar agendamento',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'sealing-orders/scheduling/:id/edit',
                    name:     'sealing-orders.scheduling.edit',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/scheduling/edit/index'),
                    nav:      false,
                    title:    'Agendar ordem de selagem',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealing-orders/scheduling/:id/confirm',
                    name:     'sealing-orders.scheduling.confirm',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/scheduling/confirm/index'),
                    nav:      false,
                    title:    'Confirmar agendamento de ordem de selagem',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== sealing-orders/awaiting-execution ====================
                {
                    route:    'sealing-orders/awaiting-execution',
                    name:     'sealing-orders.awaiting-execution.index',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/awaiting-execution/index'),
                    nav:      true,
                    title:    'Ordens de selagem a aguardar realização',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'sealing-orders/awaiting-execution/:id/edit',
                    name:     'sealing-orders.awaiting-execution.edit',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/awaiting-execution/edit/index'),
                    nav:      false,
                    title:    'Realizar ordem de selagem',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealing-orders/awaiting-execution/:id/reassign',
                    name:     'sealing-orders.awaiting-execution.reassign',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/awaiting-execution/reassign/index'),
                    nav:      false,
                    title:    'Alterar técnico de manutenção',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealing-orders/awaiting-execution/:id/reassign-inspector',
                    name:     'sealing-orders.awaiting-execution.reassign-inspector',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/awaiting-execution/reassign-inspector/index'),
                    nav:      false,
                    title:    'Alterar inspector de ordem de selagem',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'sealing-orders/awaiting-execution/:id/reschedule',
                    name:     'sealing-orders.awaiting-execution.reschedule',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/awaiting-execution/reschedule/index'),
                    nav:      false,
                    title:    'Reagendar ordem de selagem',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== sealing-orders/in-execution ====================
                {
                    route:    'sealing-orders/in-execution',
                    name:     'sealing-orders.in-execution.index',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/in-execution/index'),
                    nav:      true,
                    title:    'Ordens de selagem em realização',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.in-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== sealing-orders/results ====================
                {
                    route:    'sealing-orders/results',
                    name:     'sealing-orders.results.index',
                    moduleId: PLATFORM.moduleName('modules/sealing-orders/results/index'),
                    nav:      true,
                    title:    'Ordens de selagem realizadas',
                    settings: {
                        menu:        'sealing-orders',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.sealing-orders'},
                            {title: 'page.breadcrumbs.results'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== expertises/requests ====================
                {
                    route:    'expertises/requests',
                    name:     'expertises.requests.index',
                    moduleId: PLATFORM.moduleName('modules/expertises/requests/index'),
                    nav:      true,
                    title:    'Pedido de peritagem',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.request'},
                            {title: 'page.breadcrumbs.new-record'},
                        ],
                    },
                },
                // ==================== expertises/awaiting-assignment ====================
                {
                    route:    'expertises/awaiting-assignment',
                    name:     'expertises.awaiting-assignment.index',
                    moduleId: PLATFORM.moduleName('modules/expertises/awaiting-assignment/index'),
                    nav:      true,
                    title:    'Peritagens a aguardar atribuição de EIIE',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.ie-assignment'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== expertises/scheduling ====================
                {
                    route:    'expertises/scheduling',
                    name:     'expertises.scheduling.index',
                    moduleId: PLATFORM.moduleName('modules/expertises/scheduling/index'),
                    nav:      true,
                    title:    'Peritagens a aguardar agendamento',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'expertises/scheduling/:id/edit',
                    name:     'expertises.scheduling.edit',
                    moduleId: PLATFORM.moduleName('modules/expertises/scheduling/edit/index'),
                    nav:      false,
                    title:    'Agendar peritagem',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'expertises/scheduling/:id/confirm',
                    name:     'expertises.scheduling.confirm',
                    moduleId: PLATFORM.moduleName('modules/expertises/scheduling/confirm/index'),
                    nav:      false,
                    title:    'Confirmar agendamento de peritagem',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.scheduling'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== expertises/awaiting-execution ====================
                {
                    route:    'expertises/awaiting-execution',
                    name:     'expertises.awaiting-execution.index',
                    moduleId: PLATFORM.moduleName('modules/expertises/awaiting-execution/index'),
                    nav:      true,
                    title:    'Peritagens a aguardar realização',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                {
                    route:    'expertises/awaiting-execution/:id/edit',
                    name:     'expertises.awaiting-execution.edit',
                    moduleId: PLATFORM.moduleName('modules/expertises/awaiting-execution/edit/index'),
                    nav:      false,
                    title:    'Realizar peritagem',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'expertises/awaiting-execution/:id/reassign',
                    name:     'expertises.awaiting-execution.reassign',
                    moduleId: PLATFORM.moduleName('modules/expertises/awaiting-execution/reassign/index'),
                    nav:      false,
                    title:    'Alterar técnico de manutenção',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'expertises/awaiting-execution/:id/reassign-inspector',
                    name:     'expertises.awaiting-execution.reassign-inspector',
                    moduleId: PLATFORM.moduleName('modules/expertises/awaiting-execution/reassign-inspector/index'),
                    nav:      false,
                    title:    'Alterar inspector de peritagem',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                {
                    route:    'expertises/awaiting-execution/:id/reschedule',
                    name:     'expertises.awaiting-execution.reschedule',
                    moduleId: PLATFORM.moduleName('modules/expertises/awaiting-execution/reschedule/index'),
                    nav:      false,
                    title:    'Reagendar peritagem',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.awaiting-execution'},
                            {title: 'page.breadcrumbs.edit-record'},
                        ],
                    },
                },
                // ==================== expertises/in-execution ====================
                {
                    route:    'expertises/in-execution',
                    name:     'expertises.in-execution.index',
                    moduleId: PLATFORM.moduleName('modules/expertises/in-execution/index'),
                    nav:      true,
                    title:    'Peritagens em realização',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.in-execution'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== expertises/results ====================
                {
                    route:    'expertises/results',
                    name:     'expertises.results.index',
                    moduleId: PLATFORM.moduleName('modules/expertises/results/index'),
                    nav:      true,
                    title:    'Peritagens realizadas',
                    settings: {
                        menu:        'expertises',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.expertises'},
                            {title: 'page.breadcrumbs.results'},
                            {title: 'page.breadcrumbs.listing'},
                        ],
                    },
                },
                // ==================== statistics ====================
                {
                    route:    'statistics',
                    name:     'statistics.index',
                    moduleId: PLATFORM.moduleName('modules/statistics/index'),
                    nav:      true,
                    title:    'Estatísticas',
                    settings: {
                        menu:        'statistics',
                        breadcrumbs: [
                            {title: 'page.breadcrumbs.statistics'},
                        ],
                    },
                },
            ]);

            config.fallbackRoute('dashboard');
        };

        this.router.configure(appRouterConfig);
    }

}
